import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { RiDoubleQuotesL } from 'react-icons/ri';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import contactImg from '../assets/images/contact.svg'
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_i855jna', 'template_bp4r24r', form.current, 'iuJTYIiJsqgMe89pn')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        toast.success(Swal.fire({
          title: 'Message Sent Successfully',
          confirmButtonColor: "#BF0603",
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }));
      }, (error) => {
        console.log(error.text);
        toast.error(error.text);
      });
  };
  const handleContextMenu = (e) => {
    e.preventDefault(); // This prevents the default context menu from appearing
  }
  return (
    <div className='pt-0 bgc'>
      <Helmet>
        <title>Contact Us - Byte Edge</title>
        <meta
          name="description"
          content="Contact us for inquiries, partnerships, or any questions you may have. We value good communication to turn ideas into innovation."
        />
      </Helmet>
      <div className='container pt-14 min-h-screen'>
        <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row bg-primaryColor bg-cover py-5 px-3">
          <div className="w-full md:basis-1/2 flex items-center justify-start">
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className="text-[#f5f3f4] font-[900] text-[2.1rem] sm:text-[50px]  w-fit h-fit overflow-hidden fadeOut"
            >
              Contact Us
            </h1>
          </div>
          <div className='basis-1/2 mt-10 sm:mt-0 bg-[#f5f3f4] px-3 rounded-l-3xl py-8'>
            <div className='sm:text-[50px] text-[#660708] font-[900] text-[2.1rem]'><RiDoubleQuotesL className='mt-auto mb-auto'/></div>
            <p className='mt-auto mb-auto px-4 text-headingColor font-[500]'> Good Communication is a bridge to turn ideas into innovation.</p>
          </div>
        </div>
        <div className="mt-10 sm:mt-20 md:mt-40 flex items-center justify-center gap-x-12 mb-4">
          <div className="lg:w-[50%] md:w-[50%] w-full">
            <div
              data-aos='fade-left'
              data-aos-duration='1500'
              data-aos-delay='300'
              className="w-full mt-8 md:mt-0  sm:min-h-fit lg:flex items-center  bg-[#d3d3d3]
              px-4 lg:px-8 py-8 rounded-md">
              <form className='w-full bg-inherit' ref={form} onSubmit={sendEmail}>
                <input type="text" placeholder='Your Name' className='mb-5 w-full p-3 focus:outline-none
                rounded-[5px] ' name='from_name' required/>
                <input type="Email" placeholder='Your Email' className='mb-5 w-full p-3 focus:outline-none
                rounded-[5px] ' name='user_email' 
                pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$' required />
                <input type="text" placeholder='Your Subject e.g. Web Development' className='mb-5 w-full p-3 focus:outline-none
                rounded-[5px] ' name='from_subject' required/>
                <textarea type="text" rows={3} placeholder='Your Message' className='mb-5 w-full p-3 focus:outline-none
                rounded-[5px]' name='message' required />
                <button className='w-full p-3 focus:outline-none rounded-[5px]
                  bg-primaryColor text-white hover:bg-smallTextColor text-center ease-linear
                  duration-150'>Send</button>
              </form>
            </div>
          </div>
          <img className='lg:w-[40%] md:w-[40%] hidden md:block lg:block' src={contactImg} alt="Byte Edge - Contact Us Image" onContextMenu={handleContextMenu} />
        </div>
      </div>
    </div>
  );
}
export default Contact;
