import React, { useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { TiThMenu } from 'react-icons/ti'
import Logo from '../assets/images/logo.svg'
const Header = () => {
    const headRef = useRef(null);
    const menuRef = useRef(null);
    const stickyHeaderFunc = () => {
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                headRef.current.classList.add('sticky_header')
            } else {
                headRef.current.classList.remove('sticky_header')
            }
        })
    }
    useEffect(() => {
        stickyHeaderFunc()

        return window.removeEventListener('scroll', stickyHeaderFunc)
    })
    const toggleMenu = () => menuRef.current.classList.toggle('show_menu')
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const handleContextMenu = (e) => {
        e.preventDefault(); // This prevents the default context menu from appearing
      }
    return (
        <header ref={headRef} className='w-full h-[80px] leading-[80px] flex items-center bgc '>
            <div className="container">
                <div className='flex items-center justify-between'>
                    <NavLink to='/' onClick={scrollTop} className='focus:outline-none'>
                        <img src={Logo} onContextMenu={handleContextMenu} className='h-[65px] w-[90%] focus:outline-none' alt="Byte Edge - Logo" />
                    </NavLink>
                    <div className="menu" onClick={toggleMenu} ref={menuRef}>
                        <nav className='flex items-center gap-10 nav_menu'>

                            <NavLink className='text-smallTextColor text-[20px] font-[500] underline focus:outline-none navi hover:text-headingColor' to='/about' onClick={scrollTop}>About</NavLink>


                            <NavLink className='text-smallTextColor text-[20px] font-[500] underline focus:outline-none navi hover:text-headingColor' onClick={scrollTop} to='/services'>Services</NavLink>

                            <NavLink className='text-smallTextColor text-[20px] font-[500] underline focus:outline-none navi hover:text-headingColor' onClick={scrollTop} to='/chatbot'>Chatbot</NavLink>

                            <NavLink className='text-smallTextColor text-[20px] font-[500] underline focus:outline-none navi hover:text-headingColor' onClick={scrollTop} to='/contact'>Contact</NavLink>
                        </nav>
                    </div>
                    <span onClick={toggleMenu} className='text-2xl  text-smallTextColor cursor-pointer lg:hidden md:hidden'>
                        <TiThMenu />
                    </span>
                </div>
            </div>
        </header>
    )
}

export default Header
