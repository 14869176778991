import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RiDoubleQuotesL } from 'react-icons/ri';
import Swal from 'sweetalert2';
const API_KEY = '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';
const Chatboat = () => {
  const chatContainerRef = useRef(null);
  const [typing, setTyping] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: 'Hello! Ask me anything',
      sender: 'ChatGPT',
    },
  ]);
  const handleSend = async (message) => {
    if (message.trim() === '') {
      Swal.fire({
        title: 'Please Ask Something!',
        confirmButtonColor: "#BF0603",
        timer: 2000,
        timerProgressBar: true
      })
      return;
    }
    const newMessage = {
      message: message,
      sender: 'user',
      direction: 'outgoing',
    };
    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setTyping(true);
    await processMessageToChatGPT(newMessages);
  };
  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = '';
      if (messageObject.sender === 'ChatGPT') {
        role = 'assistant';
      } else {
        role = 'user';
      }
      return { role: role, content: messageObject.message };
    });
    const systemMessage = {
      role: 'system',
      content:
        " Your First Response against greetings must be (Hello! I'm AI Chatbot of Byte Edge, How can I help you?) and greeting must only for first message.Speak as your name is AI Chatbot and you are a representative of BYTE EDGE. Byte Edge (SMC-Private) Limited is a software company which deals in Web Development, Desktop and Android app development, Graphic designing, and Digital Marketing. Our contact timing is 9am - 5 pm, Monday - Friday. Our Contact Information is as; for call: 03244731677 and for email: hello@byteedge.pk. If someone asks about our website then tell him https://www.byteedge.pk/ and also tell him that currently you are on our website",
    };
    const apiRequestBody = {
      model: 'gpt-3.5-turbo',
      messages: [systemMessage, ...apiMessages],
    };
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + API_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: 'ChatGPT',
          },
        ]);
        setTyping(false);
      });
  }
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages]);
  return (
    <div>
      <Helmet>
        <title>AI Chatbot - Byte Edge</title>
        <meta
          name="description"
          content="Chat with our AI Chatbot for information about Byte Edge services. Byte Edge (SMC-Private) Limited specializes in Web Development, Desktop and Android app development, graphic designing, and Digital Marketing."
        />
      </Helmet>
      <div className="pt-0 bgc">
        <div id="askme" className="container pt-14 min-h-screen ">
          <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row bg-primaryColor bg-cover py-5 px-3">
            <div className="w-full md:basis-1/2 flex items-center justify-start">
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                className="text-[#f5f3f4] font-[900] text-[2.1rem] sm:text-[50px]  w-fit h-fit overflow-hidden fadeOut"
              >
                Our Chatbot
              </h1>
            </div>
            <div className="basis-1/2 mt-10 sm:mt-0 bg-[#f5f3f4] px-3 rounded-l-3xl py-8">
              <div className="sm:text-[50px] text-[#660708] font-[900] text-[2.1rem]">
                <RiDoubleQuotesL className="mt-auto mb-auto" />
              </div>
              <p className="mt-auto mb-auto px-4 text-headingColor font-[500]">
                Programmer's conversational ambassadors.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-20 md:mt-40 mb-4">
            <div
              data-aos="fade-down"
              data-aos-duration="1300"
              data-aos-delay="300"
              className="bg-[#d3d3d3] px-4 py-2 rounded shadow  w-[90%] mx-auto"
            >
              <div className="h-[65vh] overflow-y-auto mb-4" ref={chatContainerRef}>
                <ul>
                  {messages.map((message, i) => (
                    <li
                      key={i}
                      className={`mb-2 ${message.sender === 'ChatGPT' ? 'text-primaryColor' : 'text-headingColor '
                        }`}
                    >
                      {message.message}
                    </li>
                  ))}
                  {typing && <li className="text-smallTextColor italic">Typing...</li>}
                </ul>
              </div>
              <div className="flex justify-center">
                <input
                  className="flex-1 mr-2 border bg-backcolor border-gray-300 rounded p-2 focus:outline-primaryColor"
                  type="text"
                  placeholder="Type your Message"
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleSend(event.target.value);
                      event.target.value = '';
                    }
                  }}
                  disabled={typing} />
                <button
                  className="px-4 py-2 bg-primaryColor hover:bg-smallTextColor text-white rounded"
                  onClick={() => {
                    const inputElement = document.querySelector('input');
                    handleSend(inputElement.value);
                    inputElement.value = '';
                  }}
                  disabled={typing}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Chatboat;
