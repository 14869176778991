import React, { useState, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import CountUp from 'react-countup';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { Helmet } from 'react-helmet';
import homeImg from '../assets/images/Home.svg'
import swirlLine from '../assets/images/swirl line svg.svg'
import animatedWave from '../assets/images/wave.svg'
const Home = () => {
  const [fadeIn, setFadeIn] = useState(true);
  const [displayedText, setDisplayedText] = useState('');
  const textContent = `We're more than just a software company – we're your strategic partner in unlocking the limitless potential of the digital realm. From seamless web development to cutting-edge software solutions and impactful marketing services, we're here to transform your vision into a thriving online reality.`;

  useEffect(() => {
    const interval = setInterval(() => {
      if (displayedText.length < textContent.length) {
        setDisplayedText(textContent.substring(0, displayedText.length + 1));
      } else {
        clearInterval(interval);
      }
    }, 25);

    return () => {
      clearInterval(interval);
    };
  }, [displayedText]);
  <Helmet>
    <title>Byte Edge | Leading Development Services in One Window</title>
    <meta
      name="description"
      content="Welcome to Byte Edge, a Leading Software Development Company specializing in creating innovative solutions for businesses. Our team of experts delivers custom Software, Web Applications, and Mobile Apps along with Digital Marketing to help you achieve your digital goals. Contact us today for top-notch Services."
    />
  </Helmet>
  const sections = [
    {
      title: 'Gathering Client Input',
      content: "Our initial step involves understanding the client's vision for the website. We gather relevant information about your industry, business objectives, and competitors to ensure we create a website that aligns with your needs and preferences.",
    },
    {
      title: 'Defining Features',
      content: "During this phase, we establish and outline the website's structure, functionalities, and required features. This serves as a project guideline, and we also determine content length at this stage.",
    },
    {
      title: 'Crafting a Strategic Approach',
      content: "Once we have a clear grasp of your requirements, our team of professionals works diligently to devise the best solution for your project, ensuring you receive top-notch web development services.",
    },
    {
      title: "Design and Code Implementation",
      content: "Our team of experts now commences work on the website's design and code. Our creative designers collaborate with developers to create a user-friendly and SEO-friendly design. Client approval is sought before proceeding with development.",
    },
    {
      title: "Thorough Quality Assurance",
      content: "We don't finalize the project without rigorous quality assurance. Our development team is led by professionals dedicated to ensuring that only a high-quality product or service is delivered to you, ensuring your satisfaction.",
    },
    {
      title: "Project Delivery and Support",
      content: "Prior to delivery, the developed website undergoes extensive testing to identify and rectify any bugs or issues. After this, we provide you with the finished website and offer a 2-week support program to address any queries or concerns you may have.",
    },
  ];

  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    if (openSection === index) {
      setOpenSection(null);
    } else {
      setOpenSection(index);
    }
  };
  const handleContextMenu = (e) => {
    e.preventDefault(); // This prevents the default context menu from appearing
  }
  return (
    <section className="pt-0 bgc" id="about">
      <div className="container pt-0 sm:pt-14 min-h-screen">
        <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row">
          <div className="w-full md:basis-1/2">
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className="text-headingColor font-[700] text-[2.1rem] sm:text-[30px] leading-[45px] sm:leading-[46px] mt-5 w-fit h-fit"
            >
              Welcome to <br />
              <span className='sm:text-[50px] text-primaryColor font-[900]'>
                Byte Edge: Concept to Code, Strategy to Success
              </span>
            </h1>
            <p
              data-aos="fade-left"
              data-aos-duration="1500"
              className="gap-2 text-headingColor mt-8 font-[500] sm:pl-10 text-[20px] leading-7 sm:pr-10 text-justify h-[200px] overflow-y-visible"
            >
              {displayedText}
            </p>
          </div>
          <div className={`overflow-hidden basis-1/2 mt-10 sm:mt-0 ${fadeIn ? 'fadeIn' : 'fadeOut'}`}>
            <figure data-aos="fade-right" data-aos-duration="1500" className="flex items-center justify-center">
              <img src={homeImg} alt="Byte Edge - Home Page" onContextMenu={handleContextMenu} />
            </figure>
          </div>
        </div>
        <div className="mt-20 sm:mt-40 w-full">
          <img src={swirlLine} alt="Byte Edge - swirl line - wave" onContextMenu={handleContextMenu} />
        </div>

        <div className="mt-9 sm:mt-20">
          <div>
            <h2 className='text-headingColor text-center font-[600] text-[30px] sm:text-[35px]' title='Why Byte Edge'>Why Choose Byte Edge
              <span className='text-primaryColor text-[45px] sm:text-[50px]'>?</span></h2>
            <div className="d-flex align-items-center justify-content-center mt-3 sm:mt-10">
              <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4">
                <div >
                  <Card style={{ width: '18rem', height: '100%', backgroundColor: '#16203b' }}>
                    <Card.Body >
                      <h3 className='text-center text-[23px] font-[600] uppercase text-[#f5f3f4]'> Innovative Solutions</h3>
                      <p className='font-[300] text-center leading-6 text-[#d3d3d3] mt-1 italic'>
                        We thrive on challenges and turn them into opportunities through inventive solutions that set you apart.
                      </p >
                    </Card.Body>
                  </Card>
                </div>

                <div >
                  <Card style={{ width: '18rem', height: '100%', backgroundColor: '#16203b' }}>
                    <Card.Body >
                      <h3 className='text-center text-[23px] font-[600] uppercase text-[#f5f3f4]'> Strategic Approach</h3>
                      <p className='font-[300] text-center leading-6 text-[#d3d3d3] mt-1 italic'>
                        Our holistic approach ensures that every service we provide aligns seamlessly with your goals for maximum impact.
                      </p >
                    </Card.Body>
                  </Card>
                </div>

                <div >
                  <Card style={{ width: '18rem', height: '100%', backgroundColor: '#16203b' }}>
                    <Card.Body >
                      <h3 className='text-center text-[23px] font-[600] uppercase text-[#f5f3f4]'> Reliability & Security</h3>
                      <p className='font-[300] text-center leading-6 text-[#d3d3d3] mt-1 italic'>
                        Your trust is paramount. We prioritize the security and reliability of everything we create and implement.
                      </p >
                    </Card.Body>
                  </Card>
                </div>

                <div >
                  <Card style={{ width: '18rem', height: '100%', backgroundColor: '#16203b' }}>
                    <Card.Body >
                      <h3 className='text-center text-[23px] font-[600] uppercase text-[#f5f3f4]'> Client-Centric</h3>
                      <p className='font-[300] text-center leading-6 text-[#d3d3d3] mt-1 italic'>
                        Your success fuels our passion. We're dedicated to understanding your vision and delivering beyond expectations.
                      </p >
                    </Card.Body>
                  </Card>
                </div>

                <div >
                  <Card style={{ width: '18rem', height: '100%', backgroundColor: '#16203b' }}>
                    <Card.Body >
                      <h3 className='text-center text-[23px] font-[600] uppercase text-[#f5f3f4] '> Measurable Results</h3>
                      <p className='font-[300] text-center leading-6 text-[#d3d3d3] mt-1 italic'>
                        Our focus on data-driven strategies means that the success of our services isn't just a promise – it's a measurable outcome.
                      </p >
                    </Card.Body>
                  </Card>
                </div>

                <div >
                  <Card style={{ width: '18rem', height: '100%', backgroundColor: '#16203b' }}>
                    <Card.Body >
                      <h3 className='text-center text-[23px] font-[600] uppercase text-[#f5f3f4]' > Tech Expertise</h3>
                      <p className='font-[300] text-center leading-6 text-[#d3d3d3] mt-1 italic'>
                        Our expert team comprehends advanced technologies, leveraging their potential to drive the progress of your projects.                      </p >
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-9 sm:mt-20 w-full">
          <img src={swirlLine} alt="Byte Edge - swirl line - wave" onContextMenu={handleContextMenu} />
        </div>
        <div className="mt-20 sm:mt-40 flex shadow-2xl sm:w-[80%] sm:ml-[10%] sm:mr-[10%] h-32 bg-[#d3d3d3] border-solid border-primaryColor border-y-2">
          <div className='block mx-auto my-auto'>

            <h3 className="text-headingColor text-center font-[700] text-[25px] sm:text-[35px]">
              <CountUp start={0} end={4} duration={5} />
              <span className="text-primaryColor">+</span>
            </h3>
            <p className="sm:text-[20px] text-[15px] text-headingColor text-center mb-0">Successful Years</p>
          </div>
          <div className='block mx-auto my-auto'>

            <h3 className="text-headingColor text-center font-[700] text-[25px] sm:text-[35px]">
              <CountUp start={0} end={200} duration={5} />
              <span className="text-primaryColor">+</span>
            </h3>
            <p className="sm:text-[20px] text-[15px] text-headingColor text-center mb-0">Completed Projects</p>
          </div>
          <div className='block mx-auto my-auto'>

            <h3 className="text-headingColor text-center font-[700] text-[25px] sm:text-[35px]">
              <CountUp start={0} end={100} duration={5} />
              <span className="text-primaryColor">+</span>
            </h3>
            <p className="sm:text-[20px] text-[15px] text-headingColor text-center mb-0">Appreciative Clients</p>
          </div>
        </div>
        <div className='mt-20 sm:mt-40'>
          <div >
            <h2 className='text-headingColor text-center font-[600] text-[30px] sm:text-[35px]'>Your Development Journey with Byte Edge
              <span className='text-primaryColor text-[45px] sm:text-[50px]'>?</span></h2>
          </div>
          <div className='mt-3 sm:mt-10  ml-auto mr-auto sm:w-[80%] w-[18rem]'>
            <div className="w-full ">
              <div className="w-full mx-auto">
                {sections.map((section, index) => (
                  <div
                    key={index}
                    className="border rounded-lg mb-4"
                  >
                    <div
                      className="cursor-pointer p-4 border-b flex justify-between items-center"
                      onClick={() => toggleSection(index)}
                    >
                      <h4 className="text-lg font-[600] text-primaryColor">{section.title}</h4>
                      {openSection === index ? <AiFillCaretUp /> : <AiFillCaretDown />}
                    </div>
                    {openSection === index && (
                      <div className="p-4 border-t" style={{
                        backgroundImage: `url(${animatedWave})`, // Set the background image
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }}>
                        <p className='text-headingColor'>{section.content}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;




