import React from 'react';
import { Helmet } from 'react-helmet';
import { RiDoubleQuotesL } from 'react-icons/ri';
import Top from '../assets/images/top.svg'
import Bottom from '../assets/images/bottom.svg'
const About = () => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // This prevents the default context menu from appearing
  }
  return (
    <div className="pt-0 bgc">
      <Helmet>
        <title>About Us - Byte Edge</title>
        <meta
          name="description"
          content="Revolutionizing Digital Excellence Beyond Limits. Learn about our vision and how we help businesses thrive in today's rapidly evolving landscape."
        />
      </Helmet>
      <div className="container pt-14 min-h-screen">
        <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row bg-primaryColor bg-cover py-5 px-3">
          <div className="w-full md:basis-1/2 flex items-center justify-start">
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className="text-[#f5f3f4] font-[900] text-[2.1rem] sm:text-[50px]  w-fit h-fit overflow-hidden fadeOut"
            >
              About Us
            </h1>
          </div>
          <div className='basis-1/2 mt-10 sm:mt-0 bg-[#f5f3f4] px-3 rounded-l-3xl py-8'>
            <div className='sm:text-[50px] text-[#660708] font-[900] text-[2.1rem]'><RiDoubleQuotesL className='mt-auto mb-auto' /></div>
            <p className='mt-auto mb-auto px-4 text-headingColor font-[500]'> Revolutionizing Digital Excellence Beyond Limits.</p>
          </div>
        </div>
        <div className='mt-10 sm:mt-20 md:mt-40 flex flex-col md:flex-row items-center '>
          <div className="md:w-1/2 text-center md:text-left mb-10 md:mb-0">
            <h2 className="text-headingColor text-center font-[600] text-[30px] sm:text-[35px]">
              Our <span className='text-primaryColor'>Vision</span>
            </h2>
            <p className="font-[300] leading-6 text-headingColor">
              Help your business with a dynamic digital strategy and solutions to thrive in today's rapidly evolving landscape and achieve your goals with confidence.
            </p>
          </div>
          <div className='mx-auto md:mx-0 md:w-1/2'>
            <div className='flex-col justify-center items-center '>
              <img className="w-[60%] mx-auto animate-slide-upper" src={Top} onContextMenu={handleContextMenu} alt="Byte Edge - About Us Image Top" />
              <img className="w-[60%] mx-auto -mt-20 animate-slide-bottom" src={Bottom} onContextMenu={handleContextMenu} alt="Byte Edge - About Us Image Bottom" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default About;
