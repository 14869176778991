import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
const PasswordGenerator = () => {
  const [passwordLength, setPasswordLength] = useState(12);
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const handleRangeChange = (e) => {
    setPasswordLength(e.target.value);
  };
  const generatePassword = (length) => {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const symbols = '!@#$%^&*()-_=+[]{}|;:,.<>?';
    chars += symbols;
    let password = '';
    const charsLength = chars.length;
    for (let i = 0; i < length; i++) {
      password += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return password;
  };
  const handleGenerateClick = () => {
    const generatedPasswordValue = generatePassword(passwordLength);
    setGeneratedPassword(generatedPasswordValue);
    setShowCopyIcon(true);
  };
  const handleCopyClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedPassword;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setShowCopyIcon(false);
    Swal.fire({
      title: 'Password Copied to Clipboard',
      confirmButtonColor: "#ba181b"
    });
  };
  return (
    <div className='min-h-screen flex items-center justify-center'>
      <Helmet>
        <title>Password Generator - Byte Edge</title>
        <meta
          name="description"
          content="Generate strong and secure passwords with this simple password generator tool. Set the length and copy to clipboard!"
        />
      </Helmet>
      <div className="mx-auto mt-12 p-6 bg-white rounded shadow-lg max-w-md w-full md:max-w-lg lg:max-w-2xl">
        <h2 className="mb-6 text-headingColor font-[600] text-[30px] sm:text-[35px]">Password Generator</h2>
        <div className="mb-6 ">
          <input
            type="range"
            id="lengthRange"
            min="8"
            max="50"
            value={passwordLength}
            onChange={handleRangeChange}
            className="w-full bg-gray-300 h-5 rounded-lg cursor-grab"
          />
        </div>
        <h2 className="text-headingColor text-xl mb-4">Password Length: {passwordLength}</h2>
        <button
          onClick={handleGenerateClick}
          className="bg-primaryColor text-white font-bold py-2 px-4 rounded transition duration-300 hover:bg-smallTextColor"
        >
          Generate Password
        </button>
        <div className="mt-6 p-4 border rounded shadow bg-white relative w-full flex items-center justify-between">
          <p className="text-smallTextColor text-xl font-bold mb-4 break-all">{generatedPassword}</p>
          {showCopyIcon && (
            <FaCopy
              title="Copy password to clipboard"
              onClick={handleCopyClick}
              className='text-smallTextColor text-2xl cursor-pointer'
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default PasswordGenerator;