import React, { useState } from 'react';
import chroma from 'chroma-js';
import colorbrewer from 'colorbrewer';
import jsPDF from 'jspdf';
import { Helmet } from 'react-helmet';
const ColorPaletteGenerator = () => {
  const [inputColor, setInputColor] = useState('');
  const [palette, setPalette] = useState([]);
  const [error, setError] = useState('');
  const handleInputChange = (e) => {
    setInputColor(e.target.value);
    setError('');
  };
  const generatePalette = () => {
    const baseColor = chroma.valid(inputColor) ? chroma(inputColor) : null;

    if (baseColor) {
      const colorScheme = colorbrewer.RdYlBu[5];
      const newPalette = [
        baseColor.hex(),
        ...colorScheme.map((shade) =>
          chroma.mix(baseColor, shade, 0.5, 'lab').hex()
        ),
      ];
      setPalette(newPalette);
      setError('');
    } else {
      setPalette([]);
      setError('Please enter a valid color name or code');
    }
  };
  const saveAsPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text('Generated Color Palette', 15, 15);
    let y = 30;
    palette.forEach((color, index) => {
      doc.setFillColor(color);
      doc.rect(15, y, 30, 30, 'F');
      doc.setTextColor(0, 0, 0);
      doc.text(color, 60, y + 20);
      y += 40;
    });
    doc.save('generated_palette.pdf');
  };
  const resetPalette = () => {
    setInputColor('');
    setPalette([]);
    setError('');
  };
  return (
    <div>
      <Helmet>
        <title>Color Palette Generator - Byte Edge</title>
        <meta
          name="description"
          content="Generate a beautiful color palette based on a provided color. Create stunning designs with this simple color palette generator tool."
        />
      </Helmet>
    <div className="container flex justify-center items-center min-h-screen">
      <div className="max-w-md mx-auto mt-10 p-4 border border-[#d3d3d3] rounded-lg shadow-lg bg-white">
      <h2 className="text-headingColor font-[600] text-[30px] sm:text-[35px] mb-4 ">Color Palette Generator</h2>
      <input
        type="text"
        placeholder="Enter a color"
        value={inputColor}
        onChange={handleInputChange}
        className="w-full p-2 border border-gray-300 mb-4 rounded-lg focus:outline-primaryColor"
      />
      <button
        onClick={generatePalette}
        className="w-full bg-primaryColor
        hover:bg-smallTextColor
                    </button> text-[#f5f3f4] py-2 rounded-lg mb-4"
      >
        Generate Palette
      </button>
      {error && (
        <div className="text-red-500 text-center mb-4">
          <span>{error}</span>
        </div>
      )}
      <div className="flex justify-around">
        {palette.map((color, index) => (
          <div
            key={index}
            style={{
              backgroundColor: color,
              width: '50px',
              height: '50px',
            }}
          ></div>
        ))}
      </div>
      <div className="mt-4 text-center">
        {palette.map((color, index) => (
          <div key={index} className="mb-2">
            <span className="mr-2 font-bold">{color}</span>
            <div
              style={{
                backgroundColor: color,
                width: '30px',
                height: '30px',
                display: 'inline-block',
              }}
            ></div>
          </div>
        ))}
      </div>
      {palette.length > 0 && (
        <button
          onClick={saveAsPDF}
          className="w-full bg-headingColor text-[#f5f3f4] py-2 rounded-lg mt-4"
        >
          Save as PDF
        </button>
      )}
      {palette.length > 0 && (
        <button
          onClick={resetPalette}
          className="w-full bg-headingColor hover:bg-smallTextColor text-[#f5f3f4] py-2 rounded-lg mt-4"
        >
          Reset
        </button>
      )}
    </div>
    </div>
    </div>
  );
};
export default ColorPaletteGenerator;