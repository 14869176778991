import React from 'react';
import { Helmet } from 'react-helmet';
const TermsAndConditions = () => {
  const termsContainerStyle = {
    backgroundColor: '#a4161a',
    color: '#f5f3f4',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  };
  return (
    <div>
      <Helmet>
        <title>Terms and Conditions</title>
        <meta
          name="description"
          content="Read our Terms and Conditions to understand the agreement between the website operator and the user regarding the usage of the website and its services."
        />
      </Helmet>
      <section className="py-10 bgc">
        <div className="container pt-14 min-h-screen">
          <h2 className="text-headingColor text-center font-[600] text-[30px] sm:text-[35px]">Terms and Conditions</h2>
          <div style={termsContainerStyle}>
            <li className='gap-2 text-[#f5f3f4]  font-[500]  text-[20px] leading-7 text-justify mb-4' >
              These Terms of Service (referred to as "Terms" or "Agreement") establish an understanding between the operator of the website (referred to as "Website operator," "we," "us," or "our") and the visitor or user (referred to as "User" or "you"). This Agreement outlines the fundamental terms and conditions that govern your usage of this website and all the offerings it provides, collectively referred to as the "Website" or "Services."
            </li>
            <li className='gap-2 text-[#f5f3f4]  font-[500]  text-[20px] leading-7 text-justify mb-4' >
              While this website may contain links to other websites, we want to clarify that we do not imply any form of approval, association, sponsorship, endorsement, or affiliation with any linked website, unless explicitly mentioned here.

              We strongly advise you to thoroughly examine the legal statements and terms of use of any website you visit through a link from this site. Please be aware that when you click on links to external pages or websites, you do so at your own risk.
            </li>
            <li className='gap-2 text-[#f5f3f4]  font-[500]  text-[20px] leading-7 text-justify mb-4' >
              Our website employs cookies to enhance user experience, allowing for personalized and seamless navigation. Users have the option to customize their browser settings to receive notifications and decline the installation of cookies from our website.
            </li>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
